import React from "react";
import { Link } from "react-router-dom";
import SectionTitle from "./CtaSectionTitle";

const Cta = () => {
  return (
    <div className="rs-cta main-home">
      <div className="partition-bg-wrap">
        <div className="container">
          <div className="row">
            <div className="offset-lg-6 col-lg-6 pl-70 md-pl-14">
              <SectionTitle
                sectionClass="sec-title3 mb-40"
                titleClass="title white-color mb-16"
                title="Pourquoi Choisir nos Formations ?"
                descClass="desc white-color pr-100 md-pr-0"
                description="Chaque programme de nos formations a été élaboré par nos formateurs, en s'appuyant sur leurs nombreuses années d’expertise en Consulting d'entreprise dans leur domaine.
                Nos formations sont conçues pour être riches en pratique, avec des ateliers structurés pour vous rendre opérationnel dès la fin de la formation et capable d'appliquer ces compétences dans un projet d'entreprise.
                "
              />
              {/* <div className="btn-part">
                <Link className="readon orange-btn transparent" to="/register">
                  Register Now
                </Link>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Cta;

import React from "react";
import { Link } from "react-router-dom";

const CertificationsSingle = ({ title, iconImg, description }) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "space-between",
        textAlign: "center",
        padding: "20px",
        border: "1px solid #ddd",
        borderRadius: "8px",
        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
        backgroundColor: "#fff",
        height: "100%",
      }}
    >
      {/* Icon */}
      <div style={{ marginBottom: "15px" }}>
        <img
          src={iconImg}
          alt={title}
          style={{ width: "60px", height: "60px", objectFit: "contain" }}
        />
      </div>

      {/* Title */}
      <h3
        style={{
          color: "#333",
          marginBottom: "10px",
        }}
      >
        {title}
      </h3>

      {/* Description */}
      <p
        style={{
          fontSize: "14px",
          color: "#666",
          lineHeight: "1.6",
          marginBottom: "20px",
        }}
      >
        {description}
      </p>
      <div className="sl-btn" style={{ paddingTop: "20px" }}>
        <Link className="readon orange-btn main-home" to="#">
          En savoir plus
        </Link>
      </div>
      {/* <div className="sm-btn" style={{ paddingTop: "20px" }}>
        <Link
          className="readon orange-btn"
          to="#"
          style={{ borderRadius: "0" }}
        >
          <span style={{ color: "black" }}>En Savoir Plus</span>
        </Link>
      </div> */}
    </div>
  );
};

export default CertificationsSingle;

import React, { useState, useEffect ,useRef} from "react";
import { useParams, useHistory } from "react-router-dom";
import axios from "axios";
import TinyEditor from "../../Editor/TinyEditor";

const EditFormationForm = () => {
  const { id } = useParams();
  const history = useHistory();
  const [formData, setFormData] = useState({
    titre: "",
    description: "",
    image: null,
    prix: "",
    plateforme: "Azure (Microsoft)",
    thematique: "Cloud & Infrastructure",
    details: "",
    duree: "1 jour / 4h",
  });
  const [editorError, setEditorError] = useState(false);
  const [courseImg, setCourseImg] = useState(null);
  const fileInputRef = useRef(null);

  useEffect(() => {
    // Fetch the existing formation data
    const apiEndpoint = `${process.env.REACT_APP_API_URL}/api/formations/${id}`;
    axios.get(apiEndpoint)
      .then(response => {
        const formation = response.data;
        setFormData({
          titre: formation.titre,
          description: formation.description,
          image: null,
          prix: formation.prix,
          plateforme: formation.plateforme,
          thematique: formation.thematique,
          details: formation.details,
          duree: formation.durée,
        });
        setCourseImg(`${process.env.REACT_APP_API_URL}/uploads/${formation.image}`);
      })
      .catch(error => {
        console.error('Error fetching formation:', error);
      });
  }, [id]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setFormData({
        ...formData,
        image: file,
      });
      setCourseImg(URL.createObjectURL(file)); // Generate a URL for the uploaded image
    }
  };

  const handleEditorStateChange = (newEditorState) => {
    setFormData({
      ...formData,
      details: newEditorState,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (formData.details === "") {
      setEditorError(true);
      return;
    } else {
      setEditorError(false);
    }

    const apiEndpoint = `${process.env.REACT_APP_API_URL}/api/formations/${id}`;
    const formDataToSubmit = new FormData();

    for (const key in formData) {
      formDataToSubmit.append(key, formData[key]);
    }

    try {
      const response = await fetch(apiEndpoint, {
        method: "PUT",
        body: formDataToSubmit,
      });
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      console.log("Success:", data);

      // Show success message
      alert("Formation updated successfully");

      // Redirect to the formations list
      history.push("/training/edit");
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <React.Fragment>
      <div className="rs-my-account pt-90 pb-100 md-pt-60 md-pb-80">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 md-mb-50">
              <h2 className="title pb-30 md-pb-14">Modifier une formation</h2>
              <p>Form data ID: {id}</p> {/* Debug text */}
              <div className="rs-login">
                <form className="form-group mb-30" onSubmit={handleSubmit}>
                  <div className="form-part">
                    <div className="wraper mb-30">
                      <label>
                        Titre<span>*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control-mod"
                        name="titre"
                        value={formData.titre}
                        onChange={handleChange}
                        required
                      />
                    </div>
                    <div className="wraper mb-30">
                      <label>
                        Description<span>*</span>
                      </label>
                      <textarea
                        className="form-control"
                        name="description"
                        value={formData.description}
                        onChange={handleChange}
                        required
                      />
                    </div>
                    <div className="wraper mb-30">
                      <label>
                        Prix<span>*</span>
                      </label>
                      <input
                        type="text"
                        name="prix"
                        className="form-control-mod"
                        value={formData.prix}
                        onChange={handleChange}
                        required
                      />
                    </div>
                    <div className="wraper mb-30">
                      <label>
                        Plateforme<span>*</span>
                      </label>
                      <select
                        className="form-control"
                        name="plateforme"
                        value={formData.plateforme}
                        onChange={handleChange}
                        required
                      >
                        <option value="Azure (Microsoft)">
                          Azure (Microsoft)
                        </option>
                        <option value="GCP (Google)">GCP (Google)</option>
                        <option value="Kubernetes (Linux foundation)">
                          Kubernetes (Linux foundation)
                        </option>
                      </select>
                    </div>
                    <div className="wraper mb-30">
                      <label>
                        Thématique<span>*</span>
                      </label>
                      <select
                        className="form-control"
                        name="thematique"
                        value={formData.thematique}
                        onChange={handleChange}
                        required
                      >
                        <option value="Cloud & Infrastructure">
                          Cloud & Infrastructure
                        </option>
                        <option value="Data & IA">Data & IA</option>
                        <option value="DevOps & DevSecOps">
                          DevOps & DevSecOps
                        </option>
                        <option value="Cyber Securité">Cyber Securité</option>
                      </select>
                    </div>
                    <div className="wraper mb-30">
                      <label>
                        Détails<span>*</span>
                      </label>
                      <TinyEditor
                        value={formData.details}
                        height={500}
                        menubar={true}
                        plugins=""
                        toolbar=""
                        handleEditorChange={handleEditorStateChange}
                      />
                      {editorError && (
                        <p style={{ color: "red" }}>Détails is required.</p>
                      )}
                    </div>
                    <div className="wraper mb-30">
                      <label>
                        Image<span>*</span>
                      </label>
                      <input
                        type="file"
                        className="form-control-mod"
                        name="image"
                        onChange={handleImageChange}
                        ref={fileInputRef}
                      />
                      {courseImg && (
                        <div className="img-part">
                          <img
                            src={courseImg}
                            alt={formData.titre}
                            style={{ width: "350px", height: "170px" }}
                          />
                        </div>
                      )}
                    </div>
                    <div className="wraper mb-30">
                      <label>
                        Durée<span>*</span>
                      </label>
                      <select
                        className="form-control"
                        name="duree"
                        value={formData.duree}
                        onChange={handleChange}
                        required
                      >
                        <option value="1 jour / 4h">1 jour / 4h</option>
                        <option value="1 jour / 7h">1 jour / 7h</option>
                        <option value="3 jours / 21h">3 jours / 21h</option>
                        <option value="3 jours / 28h">3 jours / 28h</option>
                      </select>
                    </div>
                  </div>
                  <div className="btns-part">
                    <button className="btn-shop orange-color" type="submit">
                      Modifier
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default EditFormationForm;

import React from "react";
import SectionTitle from "../../components/Common/SectionTitle";
import DomainsSingle from "../../CloudiaSys/home/domains/DomainsSingle";

// Domains Image
import image1 from "../../assets/img/categories/main-home/1.jpg";
import image2 from "../../assets/img/categories/main-home/2.jpg";
import image3 from "../../assets/img/categories/main-home/3.jpg";
import image4 from "../../assets/img/categories/main-home/4.jpg";
import image5 from "../../assets/img/categories/main-home/5.jpg";
import image6 from "../../assets/img/categories/main-home/6.jpg";

// Domains Icon
import icon1 from "../../assets/img/categories/main-home/icon/1.png";
import icon2 from "../../assets/img/categories/main-home/icon/2.png";
import icon3 from "../../assets/img/categories/main-home/icon/3.png";
import icon4 from "../../assets/img/categories/main-home/icon/4.png";
import icon5 from "../../assets/img/categories/main-home/icon/5.png";
import icon6 from "../../assets/img/categories/main-home/icon/6.png";

const Domains = () => {
  return (
    <div className="rs-categories main-home pt-90 pb-100 md-pt-60 md-pb-80">
      <div className="container">
        <SectionTitle
          sectionClass="sec-title3 text-center mb-44"
          subtitleClass="sub-title"
          subtitle="TOP DOMAINES"
          titleClass="title black-color"
          title="Domaines Populaires"
        />
        <div className="row mb-34">
          <div className="col-lg-4 col-md-6 mb-30">
            <DomainsSingle
              itemClass="categories-items"
              image={image1}
              title="Cloud & Infrastructure"
              iconImg={icon1}
            />
          </div>
          <div className="col-lg-4 col-md-6 mb-30">
            <DomainsSingle
              itemClass="categories-items"
              image={image2}
              title="Data Analytics"
              iconImg={icon2}
            />
          </div>
          <div className="col-lg-4 col-md-6 mb-30">
            <DomainsSingle
              itemClass="categories-items"
              image={image3}
              title="DevOps & DevSecOps"
              iconImg={icon3}
            />
          </div>
          <div className="col-lg-4 col-md-6 mb-30">
            <DomainsSingle
              itemClass="categories-items"
              image={image4}
              title="Intelligence Artificielle"
              iconImg={icon4}
            />
          </div>
          <div className="col-lg-4 col-md-6 mb-30">
            <DomainsSingle
              itemClass="categories-items"
              image={image5}
              title="Data Visualisation"
              iconImg={icon5}
            />
          </div>
          <div className="col-lg-4 col-md-6 mb-30">
            <DomainsSingle
              itemClass="categories-items"
              image={image6}
              title="Cyber Sécurité"
              iconImg={icon6}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Domains;

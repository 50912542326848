import React from "react";
import { Editor } from "@tinymce/tinymce-react";

class DisplayEditor extends React.Component {
  render() {
    const { value } = this.props;

    return (
      <div className="custom-tinymce">
        <Editor
          value={value}
          init={{
            license_key: "gpl",
            menubar: false,
            toolbar: false,
            readonly: true, // Makes the editor readonly
            branding: false,
            plugins: "autoresize",
            autoresize_bottom_margin: 0,
            autoresize_overflow_padding: 0,
            autoresize_max_height: 800,
            statusbar: false,
            setup: function (editor) {
              editor.on("init", function () {
                const contentArea = editor
                  .getContentAreaContainer()
                  .querySelector("iframe");
                if (contentArea) {
                  const doc =
                    contentArea.contentDocument ||
                    contentArea.contentWindow.document;
                  // Completely block interaction
                  doc.body.style.pointerEvents = "none";
                  doc.body.style.userSelect = "none";
                  doc.body.style.cursor = "default";
                }
              });
            },
          }}
          className="display-editor"
        />
        <style>
          {`
            .custom-tinymce iframe {
              pointer-events: none; /* Prevent iframe from receiving focus */
            }
            .custom-tinymce iframe {
              cursor: default; /* Ensure default cursor is shown */
            }
          `}
        </style>
      </div>
    );
  }
}

export default DisplayEditor;

import FeatureSingle from "../Feature/FeatureSingle";

// Feature Image
import image1 from "../../assets/img/features/icon/feature_icon_bootcamps.png";
import image2 from "../../assets/img/features/icon/feature_icon_formations.png";
import image3 from "../../assets/img/features/icon/feature_icon_certifications.png";

const FeatureStyle1 = (props) => {
  return (
    <div
      className="rs-features main-home"
      style={{
        bottom: "-30px",
      }}
    >
      <div className="container">
        <div
          className="row"
          style={{
            display: "flex",
            alignItems: "stretch",
          }}
        >
          <div
            className="col-lg-4 col-md-12 md-mb-30"
            style={{
              padding: "0",
              display: "flex",
            }}
          >
            <FeatureSingle
              itemClass="features-wrap"
              itemImg={image1}
              itemTitle="Bootcamps"
              itemDesc="Booster votre carrière en maîtrisant le Cloud, le DevOps et la plateforme Kubernetes"
            />
          </div>
          <div
            className="col-lg-4 col-md-12 md-mb-30"
            style={{
              padding: "0",
              display: "flex",
            }}
          >
            <FeatureSingle
              itemClass="features-wrap"
              itemImg={image2}
              itemTitle="Formations"
              itemDesc="Des formations conçues pour atteindre l'excellence et répondre aux exigences du marché de l’IT"
            />
          </div>
          <div
            className="col-lg-4 col-md-12"
            style={{
              padding: "0",
              display: "flex",
            }}
          >
            <FeatureSingle
              itemClass="features-wrap"
              itemImg={image3}
              itemTitle="Certifications"
              itemDesc="Obtenez vos certifications Azure, GCP et Kubernetes grâce à l'expertise de nos formateurs qualifiés"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default FeatureStyle1;

import React, { useState } from "react";
import { Link } from "react-router-dom";

const CourseSingle = (props) => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <div
      className={`${props.itemClass} course-container`}
      style={{
        position: "relative",
        overflow: "hidden",
        height: "300px", // Adjust as needed
        width: "100%",
      }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      {/* Image Section */}
      <div
        style={{
          height: "100%",
          backgroundImage: `url(${props.image})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
        }}
      ></div>

      {/* Price Section */}
      <div
        style={{
          position: "absolute",
          top: isHovered ? "10px" : "50%",
          right: isHovered ? "10px" : "50%",
          transform: isHovered ? "translate(0, 0)" : "translate(50%, -50%)",
          backgroundColor: "#ff5421",
          padding: "5px 10px",
          borderRadius: "5px",
          color: "white",
          opacity: isHovered ? 1 : 0,
          transition: "all 0.3s ease",
          zIndex: 10,
        }}
      >
        <span className="price">{props.pricing}</span>
      </div>

      {/* Content Section */}
      <div
        className="content-part"
        style={{
          position: "absolute",
          bottom: 0,
          left: 0,
          width: "100%",
          height: isHovered ? "100%" : "40%", // Expand on hover
          backgroundColor: "rgba(255, 255, 255, 0.9)", // Slightly transparent background
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          padding: "15px",
          boxSizing: "border-box",
          transition: "height 0.3s ease", // Smooth transition
        }}
      >
        <h3
          className="title"
          style={{
            fontSize: "18px",
            fontWeight: "bold",
            margin: "0 0 10px 0",
          }}
        >
          <Link to="/course/course-single">{props.title}</Link>
        </h3>

        {/* New Paragraph Section */}
        <div
          style={{
            display: isHovered ? "block" : "none", // Only show on hover
            fontSize: "14px",
            color: "#333",
            margin: "10px 0",
            transition: "opacity 0.3s ease",
          }}
        >
          <span>2 jours - 1420 €</span>
          <br />
          <span>19/11/2024 - Classe à distance garantie</span>
        </div>

        <ul
          className="meta-part"
          style={{
            margin: 0,
            padding: 0,
            listStyle: "none",
            display: "flex",
            gap: "15px",
            flexWrap: "wrap",
            fontSize: "14px",
            color: "#555",
            justifyContent: "space-between",
          }}
        >
          <li
            className="user"
            style={{ display: "flex", alignItems: "center" }}
          >
            <i
              className="fa fa-signal"
              style={{ marginRight: "5px", color: "#555" }}
            ></i>
            {props.level}
          </li>
          {/* <li
            className="user"
            style={{ display: "flex", alignItems: "center" }}
          >
            <i
              className="fa fa-file"
              style={{ marginRight: "5px", color: "#555" }}
            ></i>
            {props.lessonsQuantity} Lessons
          </li> */}
        </ul>
      </div>
    </div>
  );
};

export default CourseSingle;

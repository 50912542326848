import React from "react";
import SectionTitle from "../../../components/Common/SectionTitle";

// Domains Icon
import icon1 from "../../../assets/img/home/azure.png";
import icon2 from "../../../assets/img/home/gcp.png";
import icon3 from "../../../assets/img/home/kubernetes.png";
import CertificationsSingle from "./CertificationSingle";

const Certifications = () => {
  return (
    <div className="rs-categories main-home pt-90 pb-100 md-pt-60 md-pb-80">
      <div className="container">
        <SectionTitle
          sectionClass="sec-title3 text-center mb-44"
          subtitleClass="sub-title"
          subtitle="CERTIFICATIONS"
          titleClass="title black-color"
          title="Préparez vos Certifications Azure, GCP et Kubernetes"
        />
        <div className="row mb-34">
          <div className="col-lg-4 col-md-6 mb-30">
            <CertificationsSingle
              title="Certifications Azure"
              iconImg={icon1}
              description="Devenez certifié sur le cloud Azure avec l’aide de nos formateurs certifiés Microsoft (MCT). Formations avec les supports officiels de Microsoft"
            />
          </div>
          <div className="col-lg-4 col-md-6 mb-30">
            <CertificationsSingle
              title="Certifications GCP"
              iconImg={icon2}
              description="Devenez certifié sur le Google Cloud Plateform (GCP) avec l’aide de nos formateurs certifiés GCP"
            />
          </div>
          <div className="col-lg-4 col-md-6 mb-30">
            <CertificationsSingle
              title="Certifications Kubernetes"
              iconImg={icon3}
              description="Préparez l’examen du CKA, CKAD ou CKS de la CNCF et la Linux Foundation avec l’aide de nos formateurs certifiés Kubernetes"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Certifications;

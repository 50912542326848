import React, { useState, useEffect } from "react";
import Header from "../../../components/Layout/Header/Header";
import OffWrap from "../../../components/Layout/Header/OffWrap";
import Footer from "../../../components/Layout/Footer/Footer";
import SearchModal from "../../../components/Layout/Header/SearchModal";
import SiteBreadcrumb from "../Breadcumb";
import axios from "axios";
import { useHistory } from "react-router-dom";

import Logo from "../../../assets/img/logo/csa.svg";
import footerLogo from "../../../assets/img/logo/csa.svg";
import bannerbg from "../../../assets/img/breadcrumbs/breadcrumbs.jpg";

const EditFormation = () => {
  const [formations, setFormations] = useState([]);
  const history = useHistory();

  useEffect(() => {
    // Update this URL to match your API endpoint
    const apiUrl = `${process.env.REACT_APP_API_URL}/api/formations`;

    axios.get(apiUrl)
      .then(response => {
        setFormations(response.data);
      })
      .catch(error => {
        console.error('Error fetching formations:', error);
      });
  }, []);

  const handleEdit = (id) => {
    history.push(`/Training/edit/${id}`);
  };

  const handleDelete = (id) => {
    if (window.confirm('Are you sure you want to delete this formation?')) {
      axios.delete(`${process.env.REACT_APP_API_URL}/api/formations/${id}`)
        .then(() => {
          setFormations(formations.filter(formation => formation.id !== id));
        })
        .catch(error => {
          console.error('Error deleting formation:', error);
        });
    }
  };

  return (
    <React.Fragment>
      <OffWrap />
      <Header
        parentMenu="course"
        headerNormalLogo={Logo}
        headerStickyLogo={Logo}
        CanvasLogo={Logo}
        mobileNormalLogo={Logo}
        CanvasClass="right_menu_togle hidden-md"
        headerClass="full-width-header header-style1 home8-style4"
        TopBar="enable"
        TopBarClass="topbar-area home8-topbar"
        emailAddress="cloudiasys.academy.yt@gmail.com"
        Location="78 AVENUE DES CHAMPS ELYSEES 75008 PARIS "
      />

      <SiteBreadcrumb
        pageTitle="Manage formation"
        innerClass="breadcrumbs-text"
        pageName="Formations"
        breadcrumbsImg={bannerbg}
      />

      <div className="container my-5">
        <h2>Manage Formations</h2>
        <table className="table table-striped">
          <thead>
            <tr>
              <th>#</th>
              <th>Title</th>
              <th>Thematic</th>
              <th>Platform</th>
              <th>Duration</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {formations.map((formation, index) => (
              <tr key={formation.id}>
                <td>{index + 1}</td>
                <td>{formation.titre}</td>
                <td>{formation.thematique}</td>
                <td>{formation.plateforme}</td>
                <td>{formation.durée}</td>
                <td>
                  <button
                    className="btn-shop orange-color"
                    onClick={() => handleEdit(formation.id)}
                  >
                    Edit
                  </button>
                  <button
                    className="btn-shop orange-color"
                    onClick={() => handleDelete(formation.id)}
                  >
                    Delete
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <Footer
        footerClass="rs-footer home9-style main-home"
        footerLogo={footerLogo}
      />
      <SearchModal />
    </React.Fragment>
  );
};

export default EditFormation;
